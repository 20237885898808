// const BASE_URL = "https://sansaar.ainocular.com/api/v1"
// const BASE_URL = "https://sansaar.co.in/api/v1"

const BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://sansaar.co.in/api/v1";


const apiUrl = {
    collection: {
        getAll: `${BASE_URL}/collection/all`,
        getMaterials: (collectionId) => `${BASE_URL}/collection/get-materials/${collectionId}`,
        getTrending: `${BASE_URL}/collection/trending`
    },
    contactUs: `${BASE_URL}/util/contact-form`,
    store: `${BASE_URL}/store/get-all`,
    search: (searchTerm) => `${BASE_URL}/util/search?q=${searchTerm}`,
    inspiredLiving: (articleType) => `${BASE_URL}/article/by-type?type=${articleType}`,
    inspiredLivingSearch: (articleType, searchTerm) => `${BASE_URL}/article/search?type=${articleType}&q=${searchTerm}`,
};

export default apiUrl;

import React from 'react'

function Loader() {
  return (
    <div className='lyt-loader'>
        <div className="lyt-loader__loader"></div>
    </div>
  )
}

export default Loader